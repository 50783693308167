<template>
    <div class="personalinfoContainerx">
        <div class="window">
            <div class="windowContent">
                <img v-if="imageUrl != ''" :src="imageUrl" class="personIcon">
                <img v-if="imageUrl==''" src="../../assets/default_avatar.png" class="personIcon">
                <div class="nameItem">{{user.name}}</div>
                <div class="accountItem">{{user.account_name}}</div>
                <div class="emailItem">{{$t('m.infolianxiyouxiang')}}: {{user.email}}</div>
                <el-upload
                    v-if="showChinese == 'english'"
                  class="uploadIcon1"
                  action="111"
                  :show-file-list = "false"
                  :before-upload="beforeAvatarUpload">
                  <button class="uploadIconBtn1">{{$t('m.infoshangchuantouxiang')}}</button>
                </el-upload>

                <el-upload
                    v-if="showChinese != 'english'"
                  class="uploadIcon"
                  action="111"
                  :show-file-list = "false"
                  :before-upload="beforeAvatarUpload">
                  <button class="uploadIconBtn">{{$t('m.infoshangchuantouxiang')}}</button>
                </el-upload>


                <!-- <div class="eventItem1">
                    <div v-for=" (x,index) in all_events" :key="index"  class="eventInfo">
                        
                        {{$t('m.cansaikemu')+ ':' + x +'&nbsp;&nbsp;&nbsp;'}}
                    </div>
                    <div v-if="showChinese != 'english' && projetCode == '22-23FBLA'" class="eventInfo">
                        
                        {{'必考项目:Economics'}}
                    </div>
                </div> -->

               
            </div>
        </div>
    </div>
</template>

<script>
import {getUser,uploadPic,updateUserAvatar} from '../../api/index'
import {getUserId,getUserType,getProjectCode,getProjectLanguage} from '../../utils/store'
import '../../assets/common/font.css'

export default {
    data(){
        return{
            user:{},
            user_type:getUserType(),
            imageUrl:'',
            all_events:[],
            showChinese:getProjectLanguage(),
            projetCode:getProjectCode()
        }
    },
    mounted(){
        this.getUser()
    },
    methods:{
        getUser(){
            getUser(getUserId(),getUserType(),getProjectCode()).then(res=>{
                if(res.data.code===0){
                    this.user = res.data.data
                    console.log(1111,this.user)
                    if (this.user.avatar_url != ''){
                        this.imageUrl = this.user.avatar_url;
                    }else{
                        this.imageUrl = '';
                    }

                    this.all_events = this.user.all_events

                }
            })
        },
        beforeAvatarUpload(file){
            const param = new FormData()
            param.append('file', file)
            param.append('function', 'uploadPic')

            uploadPic(param).then(res=>{
                if(res.data.code===0){
                    this.imageUrl = res.data.data
                    this.updateUserAvatar(this.imageUrl)
                }
            })
        },
        updateUserAvatar(url){
            updateUserAvatar(getUserId(),url).then(res=>{
                this.getUser()
            })
        },
        handleEventName(event){
            if(event){
                let event_array = event.split(" ")
                return event_array[0]
            }else{
                return ''
            }
        }
    }
}
</script>

<style lang="less">
.personalinfoContainerx{
    width: 100%;

    margin: 0 auto;
    .window{
        text-align: center;
        position: relative;
        
        .windowContent{
            width: 100%;
            height: 100%;
            overflow: hidden;
           
           
        }
    }
     .itemLine{
        // position: absolute;
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: 5vh auto;
        .item{
            width: 45%;
            padding-bottom: 10px;
            border-bottom: 2px solid #4E8AFC;
            color: #4E8AFC;
            font-size: 2.2vh;
            font-family: Microsoft YaHei;
            font-weight: bold;
        }
    }
    .personIcon{
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #4E8AFC;
        margin: 0 auto;
        margin-top: 50px;
        font-family: Montserrat-SemiBold;
    }
    .uploadIcon{
        border-radius: 15px;
        background-color: #4E8AFC;
        margin: 20px auto;
        width: 100px;
        height: 20px;
        line-height: 20px;
        font-family: Montserrat-SemiBold;
    }
    .uploadIconBtn{
        // position: absolute;
        border: none;
        width: 80px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #4E8AFC;
        margin-left: 0px;
        margin-top: 0px;
        text-align: center;
        color: white;
        font-family: Montserrat-SemiBold;
    }
    .uploadIcon1{
        border-radius: 15px;
        background-color: #4E8AFC;
        margin: 20px auto;
        width: 200px;
        height: 20px;
        line-height: 20px;
        font-family: Montserrat-SemiBold;
    }
    .uploadIconBtn1{
        // position: absolute;
        border: none;
        width: 180px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #4E8AFC;
        margin-left: 0px;
        margin-top: 0px;
        text-align: center;
        color: white;
        font-family: Montserrat-SemiBold;
    }
    .nameItem{
        text-align: center;
        margin-top: 20px;
        font-size: 26px;
        font-family: Montserrat;
        font-weight: 600;
        line-height: 49px;
        color: #E64F29;
    }
    .accountItem{
        text-align: center;
        color: #4E8AFC;
        font-size: 14px;
        font-weight: bold;
        font-family: Montserrat-SemiBold;
    }
    .emailItem{
        margin-top: 6px;
        color: #4E8AFC;
        font-size: 14px;
        font-weight: bold;
        font-family: Montserrat-SemiBold;
    }
    .eventItem1{
        text-align: left;
        width: 800px;
        margin: 80px auto;
        color: #4E8AFC;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }
    .eventInfo{
        margin-top: 20px;
        color: #4E8AFC;
        font-size: 16px;
        font-family: Montserrat-SemiBold;
        font-weight: 600;
    }
    
}


</style>